import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.scss';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";



import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === "development") {
  // require('dotenv').config()
}
// Initialize Sentry (place before ReactDOM.render)
// Sentry.init({
//   dsn: "http://d4863b4c23d1e30b0e66f1c4a670ba89@90.23.61.250:9001/2",  // Changed port to 9001
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,  // Set to 1.0 for testing
//   environment: process.env.NODE_ENV,
//   debug: true  // Enable debug mode temporarily
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
