const numberToWords = (num) => {
    const units = ["zéro", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
    const teens = ["dix", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
    const tens = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];

    const convertTens = (n) => {
        if (n < 10) return units[n];
        if (n < 20) return teens[n - 10];
        const ten = Math.floor(n / 10);
        const unit = n % 10;
        return tens[ten] + (unit ? "-" + units[unit] : "");
    };

    const convertHundreds = (n) => {
        const hundred = Math.floor(n / 100);
        const rest = n % 100;
        return (hundred ? (hundred === 1 ? "cent" : units[hundred] + " cent") : "") + (rest ? " " + convertTens(rest) : "");
    };

    const convertThousands = (n) => {
        const thousand = Math.floor(n / 1000);
        const rest = n % 1000;
        return (thousand ? (thousand === 1 ? "mille" : units[thousand] + " mille") : "") + (rest ? " " + convertHundreds(rest) : "");
    };

    // Safely handle null, undefined, or invalid input
    if (num === null || num === undefined) {
        num = 0;
    }
    num = parseFloat(num);
    if (isNaN(num)) {
        num = 0;
    }

    let [euros, cents] = num.toFixed(2).split(".");
    if (cents === undefined) cents = "00";
    const eurosInWords = convertThousands(parseInt(euros));
    const centsInWords = convertTens(parseInt(cents));

    return `Le montant total s'élève à ${eurosInWords} euros et ${centsInWords} centimes`;
};

export default numberToWords;